<template>
    <div class="personal">
        <headComp></headComp>

        <div class="centerWrapper">
            <div class="top">
                <div class="logo">
                    <!-- <img src="../assets/imgs/brand.png"> -->
                    <img v-if="websiteLogo" :src="websiteLogo">
                    <div class="loginBox"></div>
                </div>
            </div>
            <div class="mainContains">
                <div class="menus">
                    <el-menu
                    :default-active="defaultActive"
                    class="el-menu-vertical-demo"
                    @open="handleOpen"
                    @close="handleClose"
                    router>
                        <el-menu-item index="/">
                            <!-- <i class="el-icon-menu"></i> -->
                            <i class="iconfont icon-shouye"></i>
                            <span slot="title">首页</span>
                        </el-menu-item>
                        <el-submenu index="2">
                            <template slot="title">
                            <!-- <i class="el-icon-location"></i> -->
                            <i class="iconfont icon-diaojiekongzhi2"></i>
                            <span>个人中心</span>
                            </template>
                                <!-- <el-menu-item index="center">账户信息（个人账号）</el-menu-item> -->
                                <el-menu-item index="center">账户基本信息</el-menu-item>
                                <el-menu-item index="address">我的收货地址</el-menu-item>
                                <!-- <el-menu-item index="subaccount" v-show="userInfo.businessType==1">子账号管理</el-menu-item> -->
                        </el-submenu>
                        <el-submenu index="3">
                            <template slot="title">
                            <!-- <i class="el-icon-location"></i> -->
                            <i class="iconfont icon-dingdan"></i>
                            <span>订单管理</span>
                            </template>
                                <el-menu-item index="myOrder" v-if="!userInfo.businessType">我的订单</el-menu-item>
                                <el-menu-item index="customOrder" v-if="userInfo.businessType">客户订单</el-menu-item>
                                <el-menu-item index="purchaseOrder" v-if="userInfo.businessType">进货单</el-menu-item>
                                <el-menu-item index="myAfterSale">我的售后</el-menu-item>
                        </el-submenu>
                        <el-menu-item index="subaccount" v-show="userInfo.businessType==1">
                            <i class="iconfont icon-zizhanghaoguanli"></i>
                            <span slot="title">子账号管理</span>
                        </el-menu-item>
                        <el-menu-item index="myShoppingCar">
                            <!-- <i class="el-icon-menu"></i> -->
                            <i class="iconfont icon-gouwuche"></i>
                            <span slot="title">我的购物车</span>
                        </el-menu-item>
                        <!-- <el-menu-item index="5">
                            <i class="el-icon-menu"></i>
                            <span slot="title">我的收藏</span>
                        </el-menu-item> -->
                        <el-menu-item index="footprint">
                            <!-- <i class="el-icon-menu"></i> -->
                            <i class="iconfont icon-wodezj"></i>
                            <span slot="title">我的足迹</span>
                        </el-menu-item>
                        <el-menu-item index="myMessage">
                            <!-- <i class="el-icon-menu"></i> -->
                            <i class="iconfont icon-tongzhi"></i>
                            <span slot="title">通知消息</span>
                        </el-menu-item>
                        <!-- <el-menu-item index="8">
                            <i class="el-icon-menu"></i>
                            <span slot="title">我的卖家秀</span>
                        </el-menu-item> -->
                        <el-menu-item index="printPrice" v-if="userInfo.businessType">
                            <i class="iconfont icon-youhuicopy"></i>
                            <span slot="title">打印价格牌</span>
                        </el-menu-item>
                    </el-menu>
                </div>
                <div class="disArea">
                    <router-view/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import headComp from "@/components/headComp.vue"
import footerComp from "@/components/footerComp.vue"
export default {
    components: {
        headComp,footerComp
    },
    data(){
        return{
            websiteLogo:"",
            defaultActive:"/",
            userInfo:{},
        }
    },
    methods: {
      handleOpen(key, keyPath) {
        // console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        // console.log(key, keyPath);
      }
    },
    created() {
        this.defaultActive=this.$route.name;
        
        if(sessionStorage.websiteLogo){
            this.websiteLogo=sessionStorage.websiteLogo
        }
        if(localStorage.AOGEYA_userInfo){
            this.userInfo=JSON.parse(localStorage.AOGEYA_userInfo)
        }
        this.$bus.$on('gettingConfig',()=>{
            // // console.log(2222,sessionStorage.websiteLogo);
            this.websiteLogo=sessionStorage.websiteLogo;
        })
    },
    watch:{
        '$route'(n,o){
            this.defaultActive=this.$route.name
        }
    }
}
</script>

<style lang="less" scoped>
.personal{
    background: #fff;
    height: 100%;
    .top{
        height: 122px;
        // padding: 25px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo{
            width: 200px;
            img{
                width: 100%;
                height: 85px;
                object-fit: contain;
            }
        }
    }
    .mainContains{
        height: calc(100vh - 60px - 122px - 30px);
        // margin-top: 87px;
        margin-top: 30px;
        display: flex;
        .menus{
            width: 200px;
            /deep/.el-menu{
                background: transparent;
                // border-right: none;

                .el-menu-item,.el-submenu>.el-submenu__title{
                    display: flex;
                    align-items: center;
                    i{
                        font-size: 14px;
                        width: 18px;
                        text-align: center;
                        margin-right: 5px;
                        vertical-align: middle;
                    }
                }
            }
        }
        .disArea{
            flex: 1;
            margin-left: 50px;
            height: 100%;
            overflow: auto;
            // overflow-x: visible;
            &::-webkit-scrollbar{
                width: 0;
            }
        }
    }
}
</style>